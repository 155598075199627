@font-face {
    font-family: "JetBrains Mono";
    src: local("JetBrains Mono");
}

.App {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-family: "JetBrains Mono", sans-serif;
    background: linear-gradient(360deg, rgba(0, 157, 255, 0.8) 0%, rgba(0, 157, 255, 0.2) 30%, white 100%);
}

.name {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.8);
}

.position {
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    font-size: 16px;
}

.main {
    border-left: solid 4px #009dff;
    margin-left: 48px;
    padding-left: 16px;
    margin-right: 64px;
}

.resume {
    margin-bottom: 8px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6)
}

.link {
    margin-bottom: 8px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6)
}

.resume .link {
    padding-left: 8px;
}

.link a {
    text-decoration: none;
    color: inherit;
}

.link span {
    margin-left: 8px;
}

.link svg {
    color: black;
}

.link:hover {
    cursor: pointer;
    color: black;
}